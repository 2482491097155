import React, { useReff } from "react";
import api from "../../configs/api";
import Swal from "sweetalert2";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import XLSX from "sheetjs-style";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "react-datepicker/dist/react-datepicker.css";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  topScrollPaper: {
    marginTop: "-60px",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "50px",
  },
});

const ConfirmModal = ({ show, handleClose, datas }) => {
  console.log(datas);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const theme = useTheme();

  const [disableDownload, setDisableDownload] = React.useState(true);

  const resetStatus = () => {
    let abortController = new AbortController();
    let signal = abortController.signal;
    (async () => {
      await api
        .post(
          `/api/activitylog/resetCycleCount?Plant=${datas.Plant}&Gedung=${datas.Gedung}&Type=${datas.Type}`,
          { signal: signal }
        )
        .then((res) => {
          Toast.fire({
            icon: "success",
            title: `${res.data.message} ✅`,
          });
          setTimeout(() => {
            onResetValues();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: `Failed to Reset Status: ${err.data.message} ❌`,
          });
        });
    })();
  };
  const classes = useStyles();

  const nodeRef = React.useRef(null);

  const onResetValues = () => {
    setDisableDownload(true);
    handleClose();
  };

  return (
    <Dialog
      PaperProps={{ style: { overflowY: "visible" } }}
      classes={{
        paperFullWidth: classes.paperFullWidth,
        dialogPaper: classes.paperFullWidth,
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      onClose={onResetValues}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogTitle id="customized-dialog-title" onClose={onResetValues}>
        Reset Status
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onResetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ overflowY: "visible" }}
        classes={{
          root: classes.dialogContentRoot,
          dialogPaper: classes.paperFullWidth,
        }}
        dividers
      >
        <div className="font-bold text-m justify-start">
          Are you sure you want to reset <br />
          the status of the Cycle Count?
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "1rem 1.5rem",
        }}
      >
        <Button
          autoFocus
          onClick={onResetValues}
          color="error"
          variant="outlined"
          endIcon={<ClearIcon />}
          style={{
            marginRight: "1rem",
          }}
        >
          cancel
        </Button>
        <Button
          style={{
            marginLeft: "1rem",
          }}
          autoFocus
          onClick={(e) => {
            // // exportToExcel(data, "data");
            resetStatus();
          }}
          color="warning"
          variant="outlined"
          endIcon={<CloudDownloadIcon />}
        >
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmModal;
