import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Logo_Nabati from "../assets/logo-nabati.svg";
import {
  MdOutlineStorage,
  MdOutlineFactCheck,
  MdOutlineArrowDropDown,
  MdOutlineShowChart,
} from "react-icons/md";
import { BsChevronDown } from "react-icons/bs";

const menuItems = [
  {
    id: 1,
    label: "Master Data",
    icon: MdOutlineStorage,
    link: "/masterdata",
  },
  {
    id: 2,
    label: "Master Location",
    icon: MdOutlineStorage,
    link: "/",
  },
  {
    id: 3,
    label: "Activities",
    icon: MdOutlineFactCheck,
    iconArrow: MdOutlineArrowDropDown,
    subMenu: true,
    subMenuItems: [
      { id: 3, label: "E-StockCard", link: "/Activities/E-StockCard" },
      { id: 4, label: "CycleCount", link: "/Activities/CycleCount" },
      { id: 4, label: "AgingStock", link: "/Activities/AgingStock" },
      { id: 5, label: "Stock Opname", link: "/Activities/StockOpname" },
    ],
  },
  {
    id: 4,
    label: "Monitoring Shipment",
    icon: MdOutlineShowChart,
    link: "/Monitoring",
  },
];

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const { user } = useSelector((state) => state.Auth);
  const menuItems = [
    {
      id: 1,
      label: "Master Data",
      icon: MdOutlineStorage,
      link: "/masterdata",
    },
    {
      id: 2,
      label: "Activities",
      icon: MdOutlineFactCheck,
      iconArrow: MdOutlineArrowDropDown,
      subMenu: true,
      subMenuItems: [
        { id: 3, label: "E-StockCard", link: "/Activities/E-StockCard" },
        { id: 4, label: "CycleCount", link: "/Activities/CycleCount" },

        ...(user.department === "DC"
          ? [{ id: 4, label: "AgingStock", link: "/Activities/AgingStock" }]
          : []),
        { id: 5, label: "Stock Opname", link: "/Activities/StockOpname" },
      ],
    },
    ...(user.department === "DC"
      ? [
        {
          id: 3,
          label: "Monitoring Shipment",
          icon: MdOutlineShowChart,
          link: "/Monitoring",
        },
      ]
      : []),
  ];
  const [submenuOpen, setSubmenuOpen] = useState(false);
  // const location = useLocation();
  // const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-nabati-500 text-white bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 scrollbar-hide ${sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll scrollbar-hide lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-yellow-500 text-white p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? "translate-x-0" : "-translate-x-64"
          }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-8pr-9 sm:px-2 ">
          {/* Logo */}
          <Link to="/" className="block">
            <div className={`ml-12 mt-8 shrink-0 items-center`}>
              <img
                src={Logo_Nabati}
                width={125}
                height={75}
                alt="logo Nabati"
              />
            </div>
          </Link>
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400 absolute right-0 top-0 mt-4 mr-4"
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
              setSubmenuOpen(false);
            }}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
        </div>

        {/* Links */}
        <div className="space-y-8 text-white overflow-auto scrollbar-hide">
          {/* Pages group */}
          <div>
            {/* Components */}
            <ul className="pt-4 text-white">
              {menuItems.map(
                ({ icon: Icon, iconArrow: IconArrow, ...menu }, index) => (
                  <Fragment key={index}>
                    <Link to={menu.link}>
                      <li className="text-white text-sm text-justify flex items-center bg-yellow-700 gap-x-4 cursor-pointer p-2 hover:bg-red-600 rounded-md mt-2">
                        <Icon className="text-2xl shrink-0 ml-2 text-white group-hover:text-red-600" />

                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          {menu.label}
                        </span>
                        {menu.subMenu && (
                          <BsChevronDown
                            className={`text-sm font-medium ml-10 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${submenuOpen && "rotate-180"
                              }`}
                            onClick={() => {
                              setSubmenuOpen(!submenuOpen);
                            }}
                          />
                        )}
                      </li>{" "}
                    </Link>
                    {menu.subMenu && submenuOpen && (
                      <ul className="">
                        {menu.subMenuItems.map((subMenuItem, j) => (
                          <Link to={subMenuItem.link} key={j}>
                            <li
                              key={subMenuItem.id}
                              className="text-white  bg-yellow-700 m-1 text-sm  flex items-center gap-x-4 cursor-pointer p-1 px-12 hover:bg-red-500 rounded-md"
                            >
                              <span className="text-sm text-white  shrink-0 ml-5  font-medium  lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                {subMenuItem.label}
                              </span>
                            </li>{" "}
                          </Link>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                )
              )}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button
              onClick={() => {
                setSidebarExpanded(!sidebarExpanded);
                setSubmenuOpen(false);
              }}
            >
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
