/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useReff } from "react";
import Swal from "sweetalert2";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import FancyCard from "../Cards/FancyCard";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "react-datepicker/dist/react-datepicker.css";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  topScrollPaper: {
    marginTop: "-60px",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "50px",
  },
});

const GalleryModal = ({ show, handleClose, datas }) => {
  console.log(datas, "Datas");
  //   const [data, setData] = React.useState([...datas]);

  const theme = useTheme();

  const classes = useStyles();

  const onResetValues = () => {
    handleClose();
  };

  return (
    <Dialog
      //   PaperProps={{ style: { overflowY: "visible" } }}
      //   classes={{
      //     paperFullWidth: classes.paperFullWidth,
      //     dialogPaper: classes.paperFullWidth,
      //     scrollPaper: classes.topScrollPaper,
      //     paperScrollBody: classes.topPaperScrollBody,
      //   }}
      // style={{ position: "initial" }}
      onClose={onResetValues}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogTitle id="customized-dialog-title" onClose={onResetValues}>
        Foto Tier Shipment {datas.Nama_Checker ? datas.Nama_Checker : ""} -{" "}
        {datas.Nomor_Shipment ? "Nomor Shipment: " + datas.Nomor_Shipment : ""}
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onResetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        // style={{ overflowY: "visible" }}
        // classes={{
        //   root: classes.dialogContentRoot,
        //   dialogPaper: classes.paperFullWidth,
        // }}

        sx={{
          margin: 0,
        }}
        dividers
      >
        <div
          style={{
            zIndex: 2000,
          }}
        >
          <FancyCard
            options={{
              Carousel: {
                infinite: false,
              },
            }}
          >
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <div className="-m-1 flex flex-wrap md:-m-2">
                {datas.Upload_Photos
                  ? datas.Upload_Photos.map((image, index) => (
                      <div
                        key={index}
                        className="flex w-full image kf-image-hover sm:w-1/2 md:w-1/3 lg:w-1/4 p-1 md:p-2 group relative"
                      >
                        <a
                          href={image.URL}
                          data-fancybox="gallery"
                          data-caption={image.Nama}
                        >
                          <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute inset-0 flex items-center justify-center">
                            <p className="text-white text-lg">{image.Nama}</p>
                          </div>
                          <img
                            className="block mx-auto h-full object-center rounded-lg shadow-md hover:scale-110 transition duration-500 cursor-pointer object-cover"
                            src={image.URL}
                            alt={`Image ${image.Nama}`}
                            title={`Image ${image.Nama}`}
                          />
                        </a>
                      </div>
                    ))
                  : "NO DATA"}
              </div>
            </div>
          </FancyCard>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex-end",
          justifyContent: "",
          padding: "1rem 1.5rem",
        }}
      >
        <Button
          autoFocus
          onClick={onResetValues}
          color="error"
          variant="outlined"
          endIcon={<ClearIcon />}
          style={{
            marginRight: "1rem",
          }}
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default GalleryModal;
