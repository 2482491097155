import { combineReducers } from "redux";
import authReducer from "./auth";
import messageReducer from "./message";
import globalReducer from "../services";

import statusReducer from "./status";
// import { api_data } from "../services/data.service";
const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  Auth: authReducer,
  Message: messageReducer,

  Status: statusReducer,
  // data: dataSlice,
  // [api_data.reducerPath]: api_data.reducer,
});

export default rootReducer;
