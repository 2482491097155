import React, { useReff } from "react";
import api from "../../configs/api";
import Swal from "sweetalert2";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  topScrollPaper: {
    marginTop: "-60px",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
    marginTop: "50px",
  },
});

const DownloadModalAging = ({ show, handleClose, datas }) => {
  console.log(datas);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    title: "left-gap",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,

    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const theme = useTheme();
  const [activity, setActivity] = React.useState("");
  const [dateRange, setDateRange] = React.useState([]);
  const [disableDate, setDisableDate] = React.useState(false);
  const [disableDownload, setDisableDownload] = React.useState(true);
  const [data, setData] = React.useState([]);

  const classes = useStyles();
  const formatDate = (date) => {
    let formattedDate = date.toLocaleDateString("en-SE", {
      timeZone: "Asia/Bangkok",
    });
    // date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    return formattedDate;
  };
  const nodeRef = React.useRef(null);

  const onResetValues = () => {
    setActivity("");
    setDateRange([]);

    setDisableDate(true);
    setDisableDownload(true);
    handleClose();
  };

  const onHandleChange = (value) => {
    if (value.length === 0) {
      setDateRange([]);
      setDisableDownload(true);
    } else {
      setDateRange([
        formatDate(value[0]),
        formatDate(value[1]) ? formatDate(value[1]) : formatDate(value[0]),
      ]);
      let abortController = new AbortController();
      let signal = abortController.signal;

      (async () => {
        await api
          .get(
            `/api/download/Aging?date1=${formatDate(value[0])}&date2=${
              formatDate(value[1]) ? formatDate(value[1]) : formatDate(value[0])
            } &Plant=${datas.Plant}&Gedung=${datas.Gedung}&Type=${datas.Type}`,
            { signal: signal }
          )
          .then((res) => {
            setData(res.data.data);
            Toast.fire({
              icon: "success",
              title: `Data Aging Succes Downloaded✅`,
            });
            setTimeout(() => {
              setDisableDownload(false);
            }, 1000);
          })
          .catch((err) => {
            if (!abortController.signal.aborted) {
              setDisableDownload(true);
              Toast.fire({
                icon: "error",
                title: `Failed to Download Data: ${err.data.message} ❌`,
              });
            }
            setDisableDownload(true);
            Toast.fire({
              icon: "error",
              title: `Failed to Download Data: ${err.data.message} ❌`,
            });
            console.log(err);
          });
      })();
    }
  };
  const submitDownload = () => {
    exportToExcel(
      data,
      `AgingStock ${datas.Plant} ${datas.Gedung} ${datas.Type} (${dateRange[0]} - ${dateRange[1]})`
    );
    setTimeout(() => {
      onResetValues();
    }, 1000);
  };

  const exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Dialog
      PaperProps={{ style: { overflowY: "visible" } }}
      classes={{
        paperFullWidth: classes.paperFullWidth,
        dialogPaper: classes.paperFullWidth,
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
      onClose={onResetValues}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogTitle id="customized-dialog-title" onClose={onResetValues}>
        Download Data{" "}
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onResetValues}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ overflowY: "visible" }}
        classes={{
          root: classes.dialogContentRoot,
          dialogPaper: classes.paperFullWidth,
        }}
        dividers
      >
        <div className="relative w-full mt-4">
          <Flatpickr
            className="form-input pl-9  text-slate-600 hover:text-slate-600 font-medium focus:border-slate-300 w-80"
            options={{
              mode: "range",
              static: true,

              //   monthSelectorType: "static",
              dateFormat: "M j, Y",
              // defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
              prevArrow:
                '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
              nextArrow:
                '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
              onReady: (selectedDates, dateStr, instance) => {
                instance.element.value = dateStr.replace("to", "-");
              },
              onChange: (selectedDates, dateStr, instance) => {
                instance.element.value = dateStr.replace("to", "-");
              },
            }}
            onClose={(date) => {
              onHandleChange(date);
            }}
          />
          <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
            <svg
              className="w-4 h-4 fill-current text-slate-500 ml-3"
              viewBox="0 0 16 16"
            >
              <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
            </svg>
          </div>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "1rem 1.5rem",
        }}
      >
        <Button
          autoFocus
          onClick={onResetValues}
          color="error"
          variant="outlined"
          endIcon={<ClearIcon />}
          style={{
            marginRight: "1rem",
          }}
        >
          cancel
        </Button>
        <Button
          style={{
            marginLeft: "1rem",
          }}
          autoFocus
          onClick={(e) => {
            // exportToExcel(data, "data");
            submitDownload();
          }}
          disabled={disableDownload}
          color="primary"
          variant="outlined"
          endIcon={<CloudDownloadIcon />}
        >
          Download File
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DownloadModalAging;
