import Tab from "@mui/material/Tab";
import React, { useMemo, useState } from "react";
import { columns, columnsRMPM } from "../utils/dataAtt";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider, useTheme, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import api from "../configs/api";
import { Button } from "@material-tailwind/react";
const Masterdata = () => {
  const { user } = useSelector((state) => state.Auth);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const globalTheme = useTheme();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState("1");
  const [isRefetching, setIsRefetching] = useState(false);

  //---------------------FG---------------------------

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  let limit = pagination.pageSize;
  let page = pagination.pageIndex + 1;
  const [sorting, setSorting] = useState([
    {
      id: "SKU",
      desc: false,
    },
  ]);
  const [sku, setSku] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");

  //---------------------RM PM---------------------------
  const [isRefetchingRMPM, setIsRefetchingRMPM] = useState(false);
  const [globalFilterRMPM, setGlobalFilterRMPM] = useState("");
  const [RMPM, setRMPM] = useState([]);
  const [rowCountRMPM, setRowCountRMPM] = useState(0);
  const [paginationRMPM, setPaginationRMPM] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [sortingRMPM, setSortingRMPM] = useState([
    {
      id: "Material",
      desc: false,
    },
  ]);
  let limitRMPM = paginationRMPM.pageSize;
  let pageRPMPM = paginationRMPM.pageIndex + 1;

  React.useEffect(() => {
    if (value === "1") {
      let abortController;
      let search = "";
      if (!globalFilter) {
        setIsLoading(true);
      } else {
        setIsLoading(true);
        search = globalFilter;
      }
      let order;
      if (sorting.length < 1) {
        setSorting([
          {
            id: "SKU",
            desc: false,
          },
        ]);
        order = sorting.map((el) => {
          const column = el.id;

          let datafix = {};
          if (el.desc) {
            datafix[column] = -1;
          } else {
            datafix[column] = 1;
          }
          return datafix;
        });
        return;
      }

      order = sorting.map((el) => {
        const column = el.id;

        let datafix = {};
        if (el.desc) {
          datafix[column] = -1;
        } else {
          datafix[column] = 1;
        }
        return datafix;
      });
      console.log({ page, limit, order: order[0], search }, "FG");

      (async () => {
        abortController = new AbortController();
        await api
          .post(
            "/api/master-dataFG",
            { page, limit, order: order[0], search },
            { signal: abortController.signal }
          )
          .then((response) => {
            setSku(response.data.data.docs ? response.data.data.docs : []);
            setRowCount(
              response.data.data.totalDocs ? response.data.data.totalDocs : 0
            );
            setTimeout(() => {
              setIsLoading(false);
              setIsError(false);
            }, 500);
            console.log(response);
          })
          .catch((error) => {
            if (!abortController.signal.aborted) {
              setSku([]);
              setIsError(false);
              console.log(error);
            }
          });
      })();
      return () => abortController.abort();
    } else if (value === "2") {
      let abortController;
      let searchRMPM = "";
      if (!globalFilterRMPM) {
        setIsLoading(true);
      } else {
        setIsLoading(true);
        searchRMPM = globalFilterRMPM;
        setPaginationRMPM({
          pageIndex: 0,
          pageSize: 15,
        });
      }
      let orderRMPM;
      if (sortingRMPM.length < 1) {
        console.log("masuk", sortingRMPM);
        // setSortingRMPM([
        //   {
        //     id: "Material",
        //     desc: false,
        //   },
        // ]);
        orderRMPM = sortingRMPM.map((el) => {
          const column = el.id;

          let datafix = {};
          if (el.desc) {
            datafix[column] = -1;
          } else {
            datafix[column] = 1;
          }
          return datafix;
        });
      }

      orderRMPM = sortingRMPM.map((el) => {
        const column = el.id;

        let datafix = {};
        if (el.desc) {
          datafix[column] = -1;
        } else {
          datafix[column] = 1;
        }
        return datafix;
      });
      console.log(
        { pageRPMPM, limitRMPM, order: orderRMPM, searchRMPM, sortingRMPM },
        "RMPM"
      );

      (async () => {
        abortController = new AbortController();
        await api
          .post(
            "/api/master-dataRMPM",
            {
              page: pageRPMPM,
              limit: limitRMPM,
              order: orderRMPM[0],
              search: searchRMPM,
            },
            { signal: abortController.signal }
          )
          .then((response) => {
            setRMPM(response.data.data.docs ? response.data.data.docs : []);
            setRowCountRMPM(
              response.data.data.totalDocs ? response.data.data.totalDocs : 0
            );
            setTimeout(() => {
              setIsLoading(false);
              setIsError(false);
            }, 500);
          })
          .catch((error) => {
            if (!abortController.signal.aborted) {
              setRMPM([]);
              setIsError(false);
              console.log(error);
            }
          });
      })();
      return () => abortController.abort();
    }
  }, [
    sorting,
    globalFilter,
    value,
    limit,
    page,
    sortingRMPM,
    globalFilterRMPM,
    limitRMPM,
    pageRPMPM,
  ]);
  const tableTheme = useMemo(
    () =>
      createTheme({
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            "&:last-child": {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        overrides: {
          MuiTableCell: {
            root: {
              color: "grey",
              padding: "0px",
            },
          },
        },
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(235,236,236)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },

        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "red", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  const setSearch = (e) => {
    setGlobalFilter(e);
    setPagination({
      pageIndex: 0,
      pageSize: 15,
    });
  };
  const setSearchRMPM = (e) => {
    setGlobalFilterRMPM(e);
    setPaginationRMPM({
      pageIndex: 0,
      pageSize: 15,
    });
  };
  return (
    <div className="m-2 bg-white">
      <div className="text-left pl-2 pb-2 font-bold text-3xl">
        <h2>Master Data SKU</h2>
        {
          user.department === "DC" ? (
            <>
            </>
          ) : (
            <Button
              variant="filled"
              color="green"
              disabled={isRefetchingRMPM ? true : false}
              onClick={() => {
                console.log("Add SKU Raw Material")
              }}
            >
              Add SKU Raw Material
            </Button>
          )
        }
      </div>


      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="FG SKU Data"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
                <Tab label="RMPM Data" value="2" style={{ color: "#FF0000" }} />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1">
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={sku ? sku : []}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableStickyHeader
                  enableGlobalFilterModes
                  enableGrouping
                  initialState={{
                    showGlobalFilter: true,
                  }}
                  enableClickToCopy
                  positionGlobalFilter="left"
                  enableColumnOrdering
                  paginationMode="server"
                  sortingMode="server"
                  muiSearchTextFieldProps={{
                    placeholder: `Search data`,
                    sx: { minWidth: "100px", minHeight: "10px" },
                  }}
                  muiTableContainerProps={{
                    sx: { maxHeight: "500px", margin: "2px" },
                  }}
                  getRowId={(row) => row.id}
                  muiTableHeadCellProps={{
                    sx: {
                      borderRight: " solid #e0e0e0",
                      alignItems: "center",
                      // "& .Mui-TableHeadCell-Content-Labels": {
                      //   padding: "0px",
                      // },
                      "& .MuiBox-root": {
                        paddingLeft: "0px",
                      },
                      backgroundColor: "white",

                      borderTop: " solid #e0e0e0",
                    },
                  }}
                  manualPagination
                  muiTableBodyCellProps={{
                    sx: {
                      borderRight: " solid #e0e0e0",
                      borderTop: " solid #e0e0e0",
                    },
                  }}
                  muiToolbarAlertBannerProps={
                    sku.length === 0
                      ? {
                        color: "error",
                        children: "Error loading data, No data found",
                      }
                      : undefined
                  }
                  muiSelectCheckboxProps={{
                    color: "secondary",
                  }}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    globalFilter,
                    isLoading,
                    density: "compact",
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                  enableMultiSort={false}
                  muiTableBodyProps={{
                    sx: {
                      //stripe the rows, make odd rows a darker color
                      "& td:nth-of-type(odd)": {
                        backgroundColor: "#f5f5f5",
                      },
                      margin: "2px",
                      padding: "2px",
                    },
                  }}
                />
              </ThemeProvider>
            </TabPanel>
            <TabPanel style={{ padding: 2 }} value="2">
              <MaterialReactTable
                columns={columnsRMPM}
                data={RMPM ? RMPM : []}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableStickyHeader
                enableGlobalFilterModes
                enableGrouping
                initialState={{
                  showGlobalFilter: true,
                }}
                enableClickToCopy
                positionGlobalFilter="left"
                enableColumnOrdering
                paginationMode="server"
                sortingMode="server"
                muiSearchTextFieldProps={{
                  placeholder: `Search data`,
                  sx: { minWidth: "100px", minHeight: "10px" },
                }}
                muiTableContainerProps={{
                  sx: { maxHeight: "500px", margin: "2px" },
                }}
                getRowId={(row) => row.id}
                muiTableHeadCellProps={{
                  sx: {
                    borderRight: " solid #e0e0e0",
                    alignItems: "center",
                    // "& .Mui-TableHeadCell-Content-Labels": {
                    //   padding: "0px",
                    // },
                    "& .MuiBox-root": {
                      paddingLeft: "0px",
                    },
                    backgroundColor: "white",

                    borderTop: " solid #e0e0e0",
                  },
                }}

                manualPagination
                muiTableBodyCellProps={{
                  sx: {
                    borderRight: " solid #e0e0e0",
                    borderTop: " solid #e0e0e0",
                  },
                }}
                muiToolbarAlertBannerProps={
                  RMPM.length === 0
                    ? {
                      color: "error",
                      children: "Error loading data, No data found",
                    }
                    : undefined
                }
                muiSelectCheckboxProps={{
                  color: "secondary",
                }}
                onGlobalFilterChange={setSearchRMPM}
                onPaginationChange={setPaginationRMPM}
                onSortingChange={setSortingRMPM}
                rowCount={rowCountRMPM}
                state={{
                  globalFilter: globalFilterRMPM,
                  isLoading,
                  density: "compact",
                  pagination: paginationRMPM,
                  showAlertBanner: isError,
                  showProgressBars: isRefetchingRMPM,
                  sorting: sortingRMPM,
                }}
                enableMultiSort={false}
                muiTableBodyProps={{
                  sx: {
                    //stripe the rows, make odd rows a darker color
                    "& td:nth-of-type(odd)": {
                      backgroundColor: "#f5f5f5",
                    },
                    margin: "2px",
                    padding: "2px",
                  },
                }}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
export default Masterdata;
