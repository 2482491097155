import React, { useState } from "react";
import SearchModal from "./header/SearchModal";
import Notifications from "./header/Notifications";
import Help from "./header/Help";
import UserMenu from "./header/UserMenu";

function Header({ sidebarOpen, setSidebarOpen }) {
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <Notifications />
            {/*  Divider */}
            <hr className="w-px h-6 bg-slate-200 mx-3" />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

// import React from "react";
// import UserDropdown from "./UserDropdown";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import Badge from "@mui/material/Badge";

// export default function Navbar() {
//   return;
// }
// <>
//   {/* Navbar */}
//   <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4 ">
//     <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
//       {/* Brand */}
//       <a
//         className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
//         href="#pablo"
//         onClick={(e) => e.preventDefault()}
//       >
//         Dashboard
//       </a>
//       {/* Form */}
//       <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
//         <div className="mr-5 relative flex w-full flex-wrap items-stretch">
//           <Badge variant="dot" color="primary">
//             <NotificationsIcon color="action" />
//           </Badge>
//         </div>
//       </form>
//       {/* User */}
//       <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
//         <UserDropdown />
//       </ul>
//     </div>
//   </nav>
//   {/* End Navbar */}
// </>
