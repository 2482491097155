import {
  FaUser,
  FaMailBulk,
  FaUserLock,
  FaWindowClose,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import logo_nabati from "../../assets/logo-nabati.svg";
import { connect } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { register_user } from "../../actions/auth";
import { FailedModal, SuccessModal } from "../../components/Modals";

const Register = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };
  const handleConfirmPasswordClick = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const { isLoggedIn } = useSelector((state) => state.Auth);
  const { message } = useSelector((state) => state.Message);

  const initialValues = {
    email: "",
    password: "",
    username: "",
    confirmPassword: "",
    fullname: "",
    role: 1,
  };

  const [data, setData] = useState(initialValues);
  const resetData = () => {
    setData(initialValues);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const dispatch = useDispatch();

  const closeModal = () => {
    setFailedModal(false);
    setSuccessModal(false);
  };
  const handleRegister = (e) => {
    dispatch(register_user(data))
      .then(() => {
        setSuccessModal(true);
        setTimeout(() => {
          closeModal();
          navigate("/login");
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log("message", err);

        setFailedModal(true);

        setTimeout(() => {
          resetData();
          closeModal();
        }, 3000);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <div className="bg-gradient-to-r from-yellow-500 block h-screen items-center justify-center p-4 pt-20 md:flex">
        {
          <FailedModal
            open={failedModal}
            closeModal={closeModal}
            title={"FAILED"}
            message={message}
          />
        }
        {
          <SuccessModal
            open={successModal}
            closeModal={closeModal}
            title="SUCCESS"
            username={data.username}
          />
        }
        <div className=" bg-no-repeat bg-left bg-register flex flex-col items-center max-w-screen-lg overflow-hidden rounded-lg shadow-lg text-white w-full md:flex-row">
          <div className="backdrop-blur-sm backdrop-filter flex flex-col items-center justify-center p-4 text-dark w-full md:w-1/2">
            <h1 className="font-medium text-3xl">User Register</h1>
            <p className="italic text-lg">Please Register your Indentity</p>
          </div>
          <div className="bg-white flex flex-col items-center p-4 space-y-8 w-full md:w-1/2">
            <div className="flex flex-col items-center">
              <h1 className="font-medium text-yellow-500 text-xl">
                Create Account
              </h1>
              <img
                className="m-2"
                src={logo_nabati}
                width={123}
                height={75}
                alt="logo Nabati"
              />
            </div>
            <form
              onSubmit={handleSubmit(handleRegister)}
              name="contactform"
              className="flex flex-col items-center space-y-3 pt-0"
            >
              <div className="relative">
                <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400">
                  <FaUser></FaUser>
                </span>
                <input
                  className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                  placeholder="Fullname"
                  type="text"
                  name="fullname"
                  value={data.fullname}
                  onChange={handleChange}
                  required
                ></input>
              </div>
              <div className="relative">
                <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400">
                  <FaUser></FaUser>
                </span>
                <input
                  className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                  placeholder="Username"
                  type="text"
                  name="username"
                  value={data.username}
                  onChange={handleChange}
                  required
                ></input>
              </div>
              <div className="relative">
                <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400">
                  <FaMailBulk></FaMailBulk>
                </span>
                <input
                  className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                  placeholder="email...."
                  type="text"
                  name="email"
                  value={data.email}
                  pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                  onChange={handleChange}
                  required
                ></input>
              </div>
              <div className="relative">
                {/* eye section */}
                <div className="absolute flex inset-y-0 items-center pl-4 text-gray-400">
                  {passwordEye === false ? (
                    <FaEye onClick={handlePasswordClick} />
                  ) : (
                    <FaEyeSlash onClick={handlePasswordClick} />
                  )}
                </div>
                <input
                  className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                  placeholder="Password...."
                  type={passwordEye === false ? "password" : "text"}
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="relative">
                {/* eye section */}
                <div className="absolute items-center mt-2 pl-4 text-gray-400">
                  {confirmPasswordEye === false ? (
                    <FaEye onClick={handleConfirmPasswordClick} />
                  ) : (
                    <FaEyeSlash onClick={handleConfirmPasswordClick} />
                  )}
                </div>
                <input
                  className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                  placeholder="Confirm Password...."
                  type={confirmPasswordEye === false ? "password" : "text"}
                  name="confirmPassword"
                  value={data.confirmPassword}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  {...register("confirmPassword", {
                    validate: (value) =>
                      value === data.password || "The passwords do not match",
                  })}
                  onChange={handleChange}
                  required
                />
                {errors.confirmPassword && (
                  <span className="flex flex-col text-sm text-red-500">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
              <div className="mt-0 grid grid-cols-2 gap-4">
                <button
                  className="bg-yellow-500 animate-pulse font-medium inline-flex items-center px-3 py-1 rounded-md shadow-sm text-white pr-100"
                  type="submit"
                >
                  <FaUserLock className="mr-2"></FaUserLock>
                  Submit
                </button>
                <Link to="/">
                  <button
                    className="bg-red-500  font-medium inline-flex items-center px-3 py-1 rounded-md shadow-sm text-white pr-100"
                    type="submit"
                  >
                    <FaWindowClose className="mr-2"></FaWindowClose>
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  test: state.Auth,
});
export default connect(mapStateToProps)(Register);
