import {
  columnsEStockCard,
  columnStockOpname,
  columnSO,
} from "../../utils/dataAtt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Tab from "@mui/material/Tab";
import React, { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  createTheme,
  Button,
  ThemeProvider,
  useTheme,
  Box,
  FormControl,
} from "@mui/material";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import DownloadModalSO from "../../components/Modals/DownloadModalSO";
import api from "../../configs/api";
import { ConfirmModal } from "../../components/Modals";

const StockOpname = () => {
  const options = {};
  const [opemModal, setOpenModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  //-----------------Modal Download--------------------
  const openModal = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };
  const openResetModal = () => {
    setResetModal(true);
  };
  const closeResetModal = () => {
    setResetModal(false);
  };
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changesValue = (event, text) => {
    setFormData({
      ...formData,
      [text]: event.target.value,
    });
  };
  const globalTheme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  //--------------------E-Stock Card--------------------
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [StockOpname, setStockOpname] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [rowCountStockOpname, setRowCountStockOpname] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sort, setSort] = useState([
    {
      id: "createdAt",
      desc: true,
    },
  ]);
  const [formData, setFormData] = useState({
    Plant: "K118",
    Gedung: "A",
    Type: "PM",
  });
  const page = pagination.pageIndex + 1;
  const limit = pagination.pageSize;
  const TotalFisik = useMemo(() => {
    let total = 0;
    StockOpname.forEach(
      (el) => {
        total += el.Jumlah;
      },
      [StockOpname]
    );
    return total;
  }, [StockOpname]);

  const columnsEStock = [
    ...columnSO,
    {
      accessorKey: "Jumlah",
      header: "Total Fisik",
      aggregationFn: "sum",
      size: 20,
      enableColumnActions: false,
      enableColumnOrdering: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      AggregatedCell: ({ cell, table }) => (
        <>
          Total by{" "}
          {table.getColumn(cell.row.groupingColumnId ?? "").columnDef.header}{" "}
          {cell.row.groupingValue} :
          <Box sx={{ color: "success.main", fontWeight: "bold" }}>
            {cell.getValue()?.toLocaleString?.()}
          </Box>
        </>
      ),
      //customize normal cell render on normal non-aggregated rows
      Cell: ({ cell }) => <>{cell.getValue()?.toLocaleString?.()}</>,
      Footer: () => (
        <Stack>
          Total Fisik:
          <Box color="warning.main">{TotalFisik?.toLocaleString?.() ?? 0}</Box>
        </Stack>
      ),
    },
  ];

  React.useEffect(() => {
    fetchDataFilter();
  }, [isLoading]);
  const [dataFilter, setDataFilter] = React.useState({
    Plant: ["K118"],
    Gedung: ["A"],
    Type: ["PM"],
  });
  const fetchDataFilter = () => {
    let abortController;
    (async () => {
      abortController = new AbortController();
      let signal = abortController.signal;
      try {
        await api
          .get("/api/masterfilter", {
            signal: signal,
          })
          .then((res) => {
            setDataFilter(res.data.data);
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
          })
          .catch((error) => {
            console.log(error);
            if (!abortController.signal.aborted) {
              setIsLoading(false);
              setIsError(false);
            }
          });
      } catch (error) {
        console.log(error);
        if (!abortController.signal.aborted) {
          setIsLoading(false);
          setIsError(false);
        }
      }
    })();
  };
  React.useEffect(() => {
    let abortController;
    let search = "";
    if (!globalFilter) {
      setIsLoading(true);
    } else {
      setIsLoading(true);
      search = globalFilter;
    }
    let order;

    if (sort.length < 1) {
      order = sort.map((el) => {
        const column = el.id;

        let datafix = {};
        if (el.desc) {
          datafix[column] = -1;
        } else {
          datafix[column] = 1;
        }
        return datafix;
      });
    }

    order = sort.map((el) => {
      const column = el.id;

      let datafix = {};
      if (el.desc) {
        datafix[column] = -1;
      } else {
        datafix[column] = 1;
      }
      return datafix;
    });
    console.log({ page, limit, order: order[0], search }, "E-Stock Card");
    (async () => {
      abortController = new AbortController();
      let signal = abortController.signal;
      try {
        await api
          .post(
            "/api/history/StockOpname",
            {
              page,
              limit,
              order: order[0],
              search,
              plant: formData.Plant,
              gedung: formData.Gedung,
              type: formData.Type,
            },
            { signal: signal }
          )
          .then((res) => {
            console.log(res.data.data.docs, "E-Stock Card");
            setStockOpname(res.data.data.docs ? res.data.data.docs : []);
            setRowCountStockOpname(
              res.data.data.totalDocs ? res.data.data.totalDocs : 0
            );
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
            if (!abortController.signal.aborted) {
              setIsLoading(false);
              setIsError(false);
              setStockOpname([]);
            }
          });
      } catch (error) {
        console.log(error);
        if (!abortController.signal.aborted) {
          setIsLoading(false);
          setIsError(false);
          setStockOpname([]);
        }
      }
    })();
    return () => abortController.abort();
  }, [
    value,
    globalFilter,
    sort,
    page,
    limit,
    formData.Plant,
    formData.Gedung,
    formData.Type,
  ]);
  const tableTheme = useMemo(
    () =>
      createTheme({
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            "&:last-child": {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        overrides: {
          MuiTableCell: {
            root: {
              color: "grey",
              padding: "0px",
            },
          },
        },
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(235,236,236)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },

        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "red", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));

  return (
    <div className="m-2 bg-white">
      {
        <DownloadModalSO
          datas={formData}
          show={opemModal}
          handleClose={closeModal}
        />
      }
      {
        <ConfirmModal
          datas={formData}
          show={resetModal}
          handleClose={closeResetModal}
        />
      }
      <div className="text-left pl-2 pb-2 font-bold text-3xl">
        <h2 className="mb-2">Stock Opname</h2>
        <Button
          disabled={isLoading}
          onClick={openModal}
          style={{
            borderRadius: 5,
            backgroundColor: `${isLoading ? "#ccc" : "#F7D716"}`,
            color: "#fff",
            padding: "9px 12px",
            fontSize: "12px",
            fontWeight: "bold",
            border: "none",
          }}
          variant="outlined"
          endIcon={<CloudDownloadIcon />}
        >
          Export Data
        </Button>
      </div>
      <div className="text-left pl-2 pb-2 font-bold text-3xl ">
        <h6 className=" text-xl ">Filter</h6>
        <FormControl sx={{ minWidth: 100, m: 1 }}>
          <InputLabel id="demo-simple-select-label">Plant</InputLabel>
          <Select
            disabled={isLoading}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.Plant}
            label="Plant"
            onChange={(e) => {
              changesValue(e, "Plant");
            }}
          >
            {dataFilter.Plant.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 100, m: 1 }}>
          <InputLabel id="demo-simple-select-label">Gedung</InputLabel>
          <Select
            disabled={isLoading}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.Gedung}
            label="Gedung"
            onChange={(e) => {
              changesValue(e, "Gedung");
            }}
          >
            {dataFilter.Gedung.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 100, m: 1 }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            disabled={isLoading}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.Type}
            label="Type"
            onChange={(e) => {
              changesValue(e, "Type");
            }}
          >
            {dataFilter.Type.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Stock Opname"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1">
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columnsEStock}
                  data={StockOpname ? StockOpname : []}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableStickyHeader
                  enableGrouping
                  enableClickToCopy
                  enableColumnOrdering
                  initialState={{
                    showGlobalFilter: true,
                  }}
                  onGlobalFilterChange={setGlobalFilter}
                  onPaginationChange={setPagination}
                  onSortingChange={setSort}
                  muiTableContainerProps={{
                    sx: { maxHeight: "500px", margin: "2px" },
                  }}
                  getRowId={(row) => row.id}
                  muiTableHeadCellProps={{
                    sx: {
                      borderRight: " solid #e0e0e0",
                      alignItems: "center",

                      "& .MuiBox-root": {
                        paddingLeft: "0px",
                      },
                      backgroundColor: "white",

                      borderTop: " solid #e0e0e0",
                    },
                  }}
                  enableGlobalFilterModes
                  muiTableBodyCellProps={{
                    sx: {
                      borderRight: " solid #e0e0e0",
                      borderTop: " solid #e0e0e0",
                    },
                  }}
                  muiSearchTextFieldProps={{
                    placeholder: `Search data`,
                    sx: { minWidth: "100px", minHeight: "10px" },
                  }}
                  positionGlobalFilter="left"
                  paginationMode="server"
                  sortingMode="server"
                  manualPagination
                  enableMultiSort={false}
                  rowCount={rowCountStockOpname}
                  muiToolbarAlertBannerProps={
                    StockOpname.length === 0
                      ? {
                          color: "error",
                          children: "Error loading data, No data found",
                        }
                      : undefined
                  }
                  muiSelectCheckboxProps={{
                    color: "secondary",
                  }}
                  state={{
                    globalFilter,
                    isLoading,
                    density: "compact",
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting: sort,
                  }}
                  muiTableBodyProps={{
                    sx: {
                      //stripe the rows, make odd rows a darker color
                      "& td:nth-of-type(odd)": {
                        backgroundColor: "#f5f5f5",
                      },
                    },
                  }}
                />
              </ThemeProvider>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
export default StockOpname;
