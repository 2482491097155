export const columns = [
  {
    accessorKey: "SKU",
    header: "SKU",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Product_Name",
    header: "Product Name",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Parent_SKU",
    header: "Parent SKU",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Parent_Product_Name",
    header: "Parent Product Name",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Kode_CB",
    header: "Kode CB",
    size: 50,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "CB_Description",
    header: "CB Description",
    size: 50,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Shelf_Life",
    header: "Shelf Life (Months)",
    size: 50,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Status",
    header: "Status",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "BUn",
    header: "BUn",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "UOM",
    header: "UOM",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Pcs",
    header: "Pcs",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Kubikasi",
    header: "Kubikasi",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Dimensi",
    header: "Dimensi",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Business_Unit",
    header: "Business Unit",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Marking_New",
    header: "Marking New",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Flavour",
    header: "Flavour",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,

    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Group_Gramasi",
    header: "Group Gramasi",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Kapasitas",
    header: "Kapasitas",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Type",
    header: "Type",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Channel",
    header: "Channel",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Country",
    header: "Country",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Focus",
    header: "Focus",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Group_Country",
    header: "Group Country",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Production",
    header: "Production",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Item_Mark_Name",
    header: "Item_Mark_Name",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Brand_Mark_Name",
    header: "Brand_Mark_Name",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];
export const columnsRMPM = [
  {
    accessorKey: "Material",
    header: "Material",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material_Description",
    header: "Material_Description",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "UoM",
    header: "UoM",
    size: 5,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Type",
    header: "Type",
    size: 5,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];
export const columnsEStockCard = [
  {
    accessorKey: "Lokasi",
    header: "Lokasi",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material",
    header: "Material",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "PO_Number",
    header: "PO_Number",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material_Description",
    header: "Material_Description",
    size: 350,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },

  {
    accessorKey: "Shift",
    header: "Shift",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Type",
    header: "Type",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Batch",
    header: "Batch",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Expired_Date",
    header: "Expired_Date",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Gedung",
    header: "Gedung",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Zona",
    header: "Zona",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "createdAt",
    header: "createdAt",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "updatedAt",
    header: "updatedAt",

    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];

export const columnInboundRMPM = [
  {
    accessorKey: "Transaction_Date",
    header: "Transaction_Date",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "createdAt",
    header: "createdAt",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "username",
    header: "username",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "NIK",
    header: "NIK",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "PO_Number",
    header: "PO_Number",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material",
    header: "Material",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material_Description",
    header: "Material_Description",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Activity",
    header: "Activity",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "From",
    header: "From",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Lokasi",
    header: "Lokasi",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Type",
    header: "Type",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Batch",
    header: "Batch",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Gedung",
    header: "Gedung",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Zona",
    header: "Zona",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];
export const columnOutboundRMPM = [
  {
    accessorKey: "Transaction_Date",
    header: "Transaction_Date",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "createdAt",
    header: "createdAt",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "username",
    header: "username",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "NIK",
    header: "NIK",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "PO_Number",
    header: "PO_Number",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material",
    header: "Material",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material_Description",
    header: "Material_Description",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Activity",
    header: "Activity",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },

  {
    accessorKey: "Lokasi",
    header: "Lokasi",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Tujuan",
    header: "Tujuan",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Type",
    header: "Type",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Batch",
    header: "Batch",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Gedung",
    header: "Gedung",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Zona",
    header: "Zona",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];
export const columnCycleCount = [
  {
    accessorKey: "Transaction_Date",
    header: "Transaction_Date",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "username",
    header: "username",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "NIK",
    header: "NIK",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material",
    header: "Material",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material_Fisik",
    header: "Material Fisik",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material_Description",
    header: "Material_Description",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Lokasi",
    header: "Lokasi",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Type",
    header: "Type",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Gedung",
    header: "Gedung",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Zona",
    header: "Zona",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "createdAt",
    header: "createdAt",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "updatedAt",
    header: "updatedAt",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Keterangan",
    header: "Keterangan",
    size: 20,
    enableColumnActions: false,
    enableColumnSorting: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];
export const columnAging = [
  {
    accessorKey: "Material",
    header: "Material",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },

  {
    accessorKey: "Material_Description",
    header: "Material_Description",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Channel",
    header: "Channel",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },

  {
    accessorKey: "expiredDate",
    header: "expired Date",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];
export const columnSO = [
  {
    accessorKey: "Material",
    header: "Material",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Material_Description",
    header: "Material_Description",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Transaction_Date",
    header: "Transaction_Date",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "createdAt",
    header: "createdAt",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "username",
    header: "fullname",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "NIK",
    header: "NIK",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "position",
    header: "position",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Gedung",
    header: "Gedung",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Type",
    header: "Type",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Lokasi",
    header: "Lokasi",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "updatedAt",
    header: "updatedAt",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Keterangan",
    header: "Keterangan",
    size: 20,
    enableColumnActions: false,
    enableColumnSorting: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];

export const columns_monitoring = [
  {
    accessorKey: "Nama_Loader",
    header: "Nama Loader",
    size: 30,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Nomor_Shipment",
    header: "Nomor Shipment",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Nomor_Mobil",
    header: "Nomor Mobil",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Nama_Checker",
    header: "Nama Checker",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Nama_Foreman",
    header: "Nama Foreman",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Total_Foto",
    header: "Total Foto",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "createdAt",
    header: "createdAt",
    size: 20,
    enableColumnActions: false,
    enableColumnOrdering: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
  },
];
