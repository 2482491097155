import Tab from "@mui/material/Tab";
import React, { useMemo, useState } from "react";
import { columns_monitoring } from "../utils/dataAtt";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  createTheme,
  ThemeProvider,
  useTheme,
  Box,
  IconButton,
  FormControl,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TabContext from "@mui/lab/TabContext";
import { Delete, Visibility, Upload } from "@mui/icons-material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { GalleryModal } from "../components/Modals";
import Flatpickr from "react-flatpickr";
import api from "../configs/api";
import { set } from "react-hook-form";
const Monitoring = () => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [formData, setFormData] = useState({
    Plant: "Majalengka",
  });
  const changesValue = (event, text) => {
    setFormData({
      ...formData,
      [text]: event.target.value,
    });
  };
  const dateLists = Array.from({ length: 7 }, (_, index) => {
    const local = new Date();
    local.setDate(local.getDate() - index);
    return local.toLocaleDateString("en-SE", {
      timeZone: "Asia/Jakarta",
    });
  });
  const globalTheme = useTheme();
  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState("1");
  const [isRefetching, setIsRefetching] = useState(false);
  const [dataFilter, setDataFilter] = React.useState({
    Plant: ["All", "Majalengka", "Rancaekek"],
  });
  const [dateRange, setDateRange] = React.useState([
    dateLists[dateLists.length - 1],
    dateLists[0],
  ]);
  //---------------------FG-----------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  let limit = pagination.pageSize;
  let page = pagination.pageIndex + 1;
  const [sorting, setSorting] = useState([
    {
      id: "createdAt",
      desc: true,
    },
  ]);
  const [sku, setSku] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");

  React.useEffect(() => {
    if (value === "1") {
      let abortController;
      let search = "";
      if (!globalFilter) {
        setIsLoading(true);
      } else {
        setIsLoading(true);
        search = globalFilter;
      }
      let order;
      if (sorting.length < 1) {
        setSorting([
          {
            id: "createdAt",
            desc: false,
          },
        ]);
        order = sorting.map((el) => {
          const column = el.id;

          let datafix = {};
          if (el.desc) {
            datafix[column] = -1;
          } else {
            datafix[column] = 1;
          }
          return datafix;
        });
        return;
      }

      order = sorting.map((el) => {
        const column = el.id;

        let datafix = {};
        if (el.desc) {
          datafix[column] = -1;
        } else {
          datafix[column] = 1;
        }
        return datafix;
      });
      console.log(
        {
          page,
          limit,
          order: order[0],
          search,
          plant: formData.Plant,
          date1: dateRange[0],
          date2: dateRange[1],
        },
        "TEST"
      );

      (async () => {
        abortController = new AbortController();
        await api
          .post(
            "/api/shipment",
            {
              page,
              limit,
              order: order[0],
              search,
              plant: formData.Plant,
              date1: dateRange[0],
              date2: dateRange[1],
            },
            { signal: abortController.signal }
          )
          .then((response) => {
            setSku(response.data.data.docs ? response.data.data.docs : []);
            setRowCount(
              response.data.data.totalDocs ? response.data.data.totalDocs : 0
            );
            setTimeout(() => {
              setIsLoading(false);
              setIsError(false);
            }, 500);
            console.log(response);
          })
          .catch((error) => {
            if (!abortController.signal.aborted) {
              setSku([]);
              setIsError(false);
              console.log(error);
            }
          });
      })();
      return () => abortController.abort();
    }
  }, [sorting, globalFilter, value, limit, page, formData, dateRange]);
  const tableTheme = useMemo(
    () =>
      createTheme({
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            "&:last-child": {
              paddingRight: 0,
              paddingLeft: 0,
            },
          },
        },
        overrides: {
          MuiTableCell: {
            root: {
              color: "grey",
              padding: "0px",
            },
          },
        },
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(235,236,236)" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },

        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "red", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
    },
  }));
  const setSearch = (e) => {
    setGlobalFilter(e);
    setPagination({
      pageIndex: 0,
      pageSize: 15,
    });
  };
  const closeModal = () => {
    setOpen(false);
    setDatas([]);
  };
  const formatDate = (date) => {
    let formattedDate = date.toLocaleDateString("en-SE", {
      timeZone: "Asia/Bangkok",
    });
    // date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    return formattedDate;
  };
  const onHandleChange = (value) => {
    setDateRange([
      formatDate(value[0]),
      formatDate(value[1]) ? formatDate(value[1]) : formatDate(value[0]),
    ]);
  };
  return (
    <div className="m-2 bg-white">
      {<GalleryModal datas={datas} show={open} handleClose={closeModal} />}
      <div className="text-left pl-2 pb-2 font-bold text-3xl">
        <h2>Monitoring Foto Tier</h2>
        <h6 className=" text-xl ">Filter</h6>
        <FormControl sx={{ minWidth: 100, m: 1 }}>
          <InputLabel id="demo-simple-select-label">Plant</InputLabel>
          <Select
            disabled={isLoading}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.Plant}
            label="Plant"
            onChange={(e) => {
              changesValue(e, "Plant");
            }}
          >
            {dataFilter.Plant.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className="relative ml-2">
          <Flatpickr
            className="form-input pl-9  text-slate-600 hover:text-slate-600 font-medium focus:border-slate-300 w-80"
            options={{
              mode: "range",
              static: true,

              //   monthSelectorType: "static",
              dateFormat: "M j, Y",
              defaultDate: [
                new Date(dateLists[0]),
                new Date(dateLists[dateLists.length - 1]),
              ],
              prevArrow:
                '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
              nextArrow:
                '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
              onReady: (selectedDates, dateStr, instance) => {
                instance.element.value = dateStr.replace("to", "-");
              },
              onChange: (selectedDates, dateStr, instance) => {
                instance.element.value = dateStr.replace("to", "-");
              },
            }}
            // disabled={disableDate}
            onClose={(date) => {
              onHandleChange(date);
            }}
          />
          <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
            <svg
              className="w-4 h-4 fill-current text-slate-500 ml-3"
              viewBox="0 0 16 16"
            >
              <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
            </svg>
          </div>
        </div>
      </div>

      <div className="bg-white ml-1">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext
            TabIndicatorProps={{ style: { background: "#FF0000" } }}
            value={value}
            index={0}
            classes={{ root: useStyles.tab }}
          >
            <Box sx={{ borderColor: "divider", p: 0 }}>
              <TabList
                index={0}
                classes={{}}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#FF0000" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Data Foto Tier"
                  value="1"
                  style={{ color: "#FF0000" }}
                />
              </TabList>
            </Box>
            <TabPanel style={{ padding: 2 }} value="1">
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns_monitoring}
                  data={sku ? sku : []}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableStickyHeader
                  enableGlobalFilterModes
                  enableGrouping
                  initialState={{
                    showGlobalFilter: true,

                    columnPinning: {
                      left: [
                        "mrt-row-actions",
                        // "Nama_Loader",
                        "Nomor_Shipment",
                      ],
                    },
                  }}
                  enableClickToCopy
                  positionGlobalFilter="left"
                  enableColumnOrdering
                  paginationMode="server"
                  sortingMode="server"
                  muiSearchTextFieldProps={{
                    placeholder: `Search data`,
                    sx: { minWidth: "100px", minHeight: "10px" },
                  }}
                  muiTableContainerProps={{
                    sx: { maxHeight: "500px", margin: "2px" },
                  }}
                  getRowId={(row) => row.id}
                  muiTableHeadCellProps={{
                    sx: {
                      borderRight: " solid #e0e0e0",
                      alignItems: "center",
                      // "& .Mui-TableHeadCell-Content-Labels": {
                      //   padding: "0px",
                      // },
                      "& .MuiBox-root": {
                        paddingLeft: "0px",
                      },
                      backgroundColor: "white",

                      borderTop: " solid #e0e0e0",
                    },
                  }}
                  manualPagination
                  muiTableBodyCellProps={{
                    sx: {
                      borderRight: " solid #e0e0e0",
                      borderTop: " solid #e0e0e0",
                    },
                  }}
                  muiToolbarAlertBannerProps={
                    sku.length === 0
                      ? {
                          color: "error",
                          children: "Error loading data, No data found",
                        }
                      : undefined
                  }
                  enableEditing
                  muiSelectCheckboxProps={{
                    color: "secondary",
                  }}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    globalFilter,
                    isLoading,
                    density: "compact",
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                  renderRowActions={({ exitEditingMode, row, table }) => (
                    <Box
                      sx={{
                        alignItems: "center",
                        gap: "1rem",
                        alignSelf: "center",
                      }}
                    >
                      {/* <Tooltip arrow placement="left" title="Edit"> */}
                      <IconButton
                        onClick={() => {
                          setDatas(row.original);
                          setOpen(true);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </Box>
                  )}
                  enableMultiSort={false}
                  muiTableBodyProps={{
                    sx: {
                      //stripe the rows, make odd rows a darker color
                      "& td:nth-of-type(odd)": {
                        backgroundColor: "#f5f5f5",
                      },
                      margin: "2px",
                      padding: "2px",
                    },
                  }}
                />
              </ThemeProvider>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
export default Monitoring;
