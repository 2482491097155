import { SET_STATUS, CLEAR_STATUS } from "../actions/types";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_STATUS:
      return { status: payload };

    case CLEAR_STATUS:
      return { status: "" };

    default:
      return state;
  }
}
