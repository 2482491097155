import axios from "axios";
import TokenService from "./token.service";
import { Navigate } from "react-router-dom";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // headers: {
  //   "Content-Type": "application/json",
  //   token: TokenService.getLocalAccessToken(),
  // },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    console.log(err, "err");
    if (err.code === "ERR_CANCELED") {
      // aborted in useEffect cleanup
      return Promise.resolve({ status: 499 });
    }
    if (
      originalConfig.url !== "/api/login" &&
      err.message === "Request failed with status code 401"
    ) {
      // Access Token was expired
      if (
        err.response.status === 401 &&
        err.response.data.message === "Token Expired!"
        // err.response.data.message === "Request need token access!"
      ) {
        originalConfig._retry = true;

        try {
          // const rs = await instance.post(
          //   "/token/generate_token",
          //   // refreshToken: TokenService.getLocalRefreshToken(),
          //   secretKey
          // );

          // const token = rs.data.data;
          // TokenService.updateLocalAccessToken(token);

          TokenService.removeUser();
          instance(originalConfig);

          window.location.reload();
          return <Navigate to="/login" />;
        } catch (_error) {
          console.log(_error);
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
