import api from "../configs/api";
import axios from "axios";
import TokenService from "../configs/token.service";

const register = (data) => {
  return api.post("/api/auth/create_user", data);
};

const secretKey = {
  apps_id: process.env.REACT_APP_APPS_ID,
  secret_key: process.env.REACT_APP_API_KEY,
};

const token = (signal) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/token/generate_token`, secretKey, {
      signal: signal,
    })
    .then((response) => {
      if (response.data.data.token) {
        TokenService.setToken(response.data.data);
      }

      return response.data.data;
    });
};

const login = async (username, password) => {
  const response = await api.post("/api/login", {
    username,
    password,
  });
  console.log(response.data.data);
  TokenService.setUser(response.data.data);
  return response.data;
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  token,
};

export default AuthService;
