import {
  FaUser,
  FaMailBulk,
  FaLock,
  FaUserLock,
  FaWindowClose,
} from "react-icons/fa";
import logo_nabati from "../../assets/logo-nabati.svg";
import { connect } from "react-redux";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link } from "react-router-dom";

import { register_user } from "../../actions/auth";

const Resetpassword = (props) => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const { isLoggedIn } = useSelector((state) => state.Auth);
  const { message } = useSelector((state) => state.Message);
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const [data, setData] = useState(initialValues);
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const dispatch = useDispatch();
  const closeModal = () => {
    setOpen(false);
  };
  const handleLogin = (e) => {
    e.preventdefault();

    dispatch(register_user(data.email, data.password, data.username))
      .then(() => {
        navigate("/login");
        window.location.reload();
      })
      .catch((err) => {
        setOpen(true);
        setTimeout(() => {
          closeModal();
        }, 3000);
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      {/* container */}
      <div className="bg-gradient-to-r from-yellow-500 block h-screen items-center justify-center p-4 pt-32 md:flex">
        {/* login.card */}
        <div className=" bg-no-repeat bg-left bg-register flex flex-col items-center max-w-screen-lg overflow-hidden rounded-lg shadow-lg text-white w-full md:flex-row">
          {/* logo */}
          <div className="backdrop-blur-sm backdrop-filter flex flex-col items-center justify-center p-4 text-dark w-full md:w-1/2">
            <h1 className="font-medium text-3xl">Reset Password</h1>
            <p className="italic text-lg">Reset your Password</p>
          </div>
          {/* form */}
          <div className="bg-white flex flex-col items-center p-4 space-y-2 w-full md:w-1/2">
            {/* title */}
            <div className="flex flex-col items-center">
              <h1 className="font-medium text-yellow-500 text-xl">
                Reset Your Password
              </h1>
              <img
                className="m-2"
                src={logo_nabati}
                width={123}
                height={75}
                alt="logo Nabati"
              />
            </div>
            {/* input */}
            <form
              name="contactform"
              className="flex flex-col items-center space-y-3 pt-0"
            >
              <div className="relative">
                <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400">
                  <FaUser></FaUser>
                </span>
                <input
                  className="border border-gray-300 outline-none text-black placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-yellow-300"
                  placeholder="Username or Email"
                  type="text"
                  name="email"
                  required
                ></input>
              </div>

              <div className="mt-0 grid grid-cols-2 gap-4">
                <button
                  className="bg-yellow-500 animate-pulse font-medium inline-flex items-center px-3 py-1 rounded-md shadow-sm text-white pr-100"
                  type="submit"
                >
                  <FaUserLock className="mr-2"></FaUserLock>
                  Submit
                </button>
                <Link to="/">
                  <button
                    className="bg-red-500  font-medium inline-flex items-center px-3 py-1 rounded-md shadow-sm text-white pr-100"
                    type="submit"
                  >
                    <FaWindowClose className="mr-2"></FaWindowClose>
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
            {/* button Link */}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  test: state.Auth,
});
export default connect(mapStateToProps)(Resetpassword);
