import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./containers/layout";
import {
  Login,
  Masterdata,
  CycleCount,
  Activities,
  NotFound,
  Monitoring,
  EStockCard,
  AgingStock,
  StockOpname,
} from "./pages";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Masterdata />} />
            <Route path="/masterdata" element={<Masterdata />} />

            <Route path="/Activities">
              <Route path="CycleCount" element={<CycleCount />} />
              <Route path="E-StockCard" element={<EStockCard />} />
              <Route path="AgingStock" element={<AgingStock />} />
              <Route path="StockOpname" element={<StockOpname />} />
            </Route>
            <Route path="/Monitoring" element={<Monitoring />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
